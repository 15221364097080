import React, { InputHTMLAttributes } from "react"
import { useField } from "formik"
import classNames from "classnames"
import { Variant } from "./helpers"

type CheckboxVariant = "checkbox" | "switch"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  name: string
  variant?: CheckboxVariant
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, variant, id, name } = props
  const [field, meta] = useField(name)

  const htmlFor = id ?? field.name

  return (
    <div className={classNames("form-check", { "form-switch": variant === "switch" })}>
      <input
        className={classNames("form-check-input", {
          "is-invalid": meta.touched && meta.error,
        })}
        type="checkbox"
        ref={ref}
        {...field}
      />
      <label className="form-check-label" htmlFor={htmlFor}>
        {label}
      </label>
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  )
})

/** export */
export default Checkbox
