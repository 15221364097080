import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import SubmitFeedbackSection from "../components/static-sections/SubmitFeedbackSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const VisionPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/feedback/",
          name: "Feedback | Norhart",
          image: `${config.siteMetadata.siteUrl}/feedback/norhart-feedback-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Feedback | Norhart"
      description="We Love Feedback"
      keywords="norhart, feeback, survey"
      imageTwitter={`${config.siteMetadata.siteUrl}/feedback/norhart-feedback-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/feedback/norhart-feedback-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="We Love Feedback"
        subtitle="Tell Us About Your Apartment Experience"
        imageTitle="Norhart Feedback"
        image="/feedback/feedback-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Norhart Resident Experience"
        tagLine="Your Feedback Inspires Us"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <SubmitFeedbackSection
        colorPalette={colorPalette}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Feedback"
        communities={props.data.allCommunity.nodes}
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allCommunity {
      nodes {
        name
        id
      }
    }
  }
`

/** export */
/** export */
export default VisionPage
