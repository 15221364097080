import React, { useEffect, useState } from "react"
import { ColorPalette } from "../Theme"
import { Formik, Form, FormikHelpers, useFormikContext } from "formik"
import Select from "../form/Select"
import TextArea from "../form/TextArea"
import Input from "../form/Input"
import Checkbox from "../form/Checkbox"
import axios from "axios"
import * as yup from "yup"

interface Props {
  colorPalette: ColorPalette
  icon: any
  iconTitle: string
  communities: any[]
}

export interface FeedbackSubmission {
  apartmentId: string
  source: string
  name: string
  phone?: string
  email?: string
  canContact: boolean
  rentScore?: number
  staffScore?: number
  amenitiesScore?: number
  cleanlinessScore?: number
  noiseScore?: number
  safetyScore?: number
  maintenanceScore?: number
  locationScore?: number
  apartmentExpierence?: number
  norhartExpierence?: number
  recommendNorhart?: number
  renewLease?: number
  comments?: string
}

const initialValues = {
  apartmentId: "",
  source: "website",
  name: "",
  phone: "",
  email: "",
  canContact: false,
  rentScore: -1,
  staffScore: -1,
  amenitiesScore: -1,
  cleanlinessScore: -1,
  noiseScore: -1,
  safetyScore: -1,
  maintenanceScore: -1,
  locationScore: -1,
  apartmentExpierence: -1,
  norhartExpierence: -1,
  recommendNorhart: -1,
  renewLease: -1,
  comments: "",
}

const RatingSchema = yup
  .number()
  .transform((value, originalValue, context) => {
    if (context.isType(value) && value != -1) return value
    return null
  })
  .nullable(true)
  .min(1)
  .max(5)

const FeedbackSchema = yup.object().shape({
  apartmentId: yup.string().uuid().required("Please select an option"),
  name: yup.string().required("Required"),
  phone: yup
    .string()
    .transform(function (value, originalValue) {
      return this.isType(value) && (value === null || value == "") ? null : value
    })
    .nullable(true)
    .matches(/\+?(\d+)?[ \.-]?\(?(\d{3})\)?[ \.-]?(\d{3})[ \.-]?(\d{4})/, "Invalid Phone Number"),
  email: yup.string().email("Invalid Email").required("Required"),
  canContact: yup.boolean().required(),
  rentScore: RatingSchema,
  staffScore: RatingSchema,
  amenitiesScore: RatingSchema,
  cleanlinessScore: RatingSchema,
  noiseScore: RatingSchema,
  safetyScore: RatingSchema,
  maintenanceScore: RatingSchema,
  locationScore: RatingSchema,
  apartmentExpierence: RatingSchema,
  norhartExpierence: RatingSchema,
  recommendNorhart: RatingSchema,
  renewLease: RatingSchema,
  comments: yup
    .string()
    .transform(function (value, originalValue) {
      return this.isType(value) && value == "" ? null : value
    })
    .nullable(true),
})

const scoreOptions = [
  ["5", "Very Satisfied"],
  ["4", "Satisfied"],
  ["3", "Neutral"],
  ["2", "Dissatisfied"],
  ["1", "Very Dissatisfied"],
]

const likelyScoreOptions = [
  ["5", "Very Likely"],
  ["4", "Somewhat Likely"],
  ["3", "Neutral"],
  ["2", "Somewhat Unlikely"],
  ["1", "Very Unlikely"],
]

const SubmitFeedbackSection: React.FC<Props> = (props) => {
  const successRef = React.useRef<HTMLInputElement>(null)
  const [didSubmit, setDidSubmit] = useState(false)
  const [apartmentId, setApartmentId] = useState<string>(null)

  const setDidSubmitHandler = (v: boolean) => {
    setDidSubmit(v)
    if (v) {
      successRef.current.scrollIntoView()
    }
  }

  return didSubmit ? (
    <div ref={successRef}>
      <Success {...props} apartmentId={apartmentId} />
    </div>
  ) : (
    <FeedbackForm {...props} setDidSubmitHandler={setDidSubmitHandler} setApartmentId={setApartmentId} />
  )
}

/** export */
export default SubmitFeedbackSection

interface FeedbackFormProps extends Props {
  setDidSubmitHandler: (v: boolean) => void
  setApartmentId: (v: string) => void
}

const FormErrorMessage: React.FC = () => {
  const { errors, touched } = useFormikContext()
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    for (const property in errors) {
      if (touched[property]) {
        setShowError(true)
        return
      }
    }
    setShowError(false)
  }, [errors, touched])

  if (showError) {
    return (
      <div className="alert alert-danger" role="alert">
        Oh no, there are some errors above!
      </div>
    )
  } else {
    return null
  }
}

const FeedbackForm: React.FC<FeedbackFormProps> = (props) => {
  const Icon = props.icon

  const { setDidSubmitHandler, setApartmentId } = props

  const apartmentOptions = props.communities.map((community) => {
    return [community.id, community.name]
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FeedbackSchema}
      onSubmit={async (values: FeedbackSubmission, { setSubmitting }: FormikHelpers<FeedbackSubmission>) => {
        await axios.post(process.env.GATSBY_FEEDBACK_URL, FeedbackSchema.cast(values))
        setSubmitting(false)
        setApartmentId(values.apartmentId)
        setDidSubmitHandler(true)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <section
            className="container-fluid sectionBaseColor pt-5 pb-5"
            style={{
              color: `${props.colorPalette.sectionText}`,
            }}
          >
            <div className="container pl-3 pr-3 text-center">
              <div className="container pb-5"></div>
              <div className="text-center pb-2">
                <Icon />
              </div>

              <div className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl" style={{ fontSize: "15px" }}>
                {props.iconTitle}
              </div>

              <hr
                className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mb-3 mt-3"
                style={{
                  borderColor: "rgba(0, 0, 0, 0.2)",
                }}
              />

              <h2
                className="contentHeroTitle"
                style={{
                  color: "#365c70",
                }}
              >
                Quick 1 Minute Survey
              </h2>

              <h4 className="contentHeroSubTitle" style={{ color: "#365c70", opacity: "0.8" }}>
                Our goal is to design and develop a better way for people to live. Your feedback is essential to helping
                us build exceptional apartments and creating a magical experience for our residents!
              </h4>
            </div>

            <div className="container pl-3 pr-3 mt-5 text-left">
              <h4>
                <strong>Choose:</strong>
              </h4>
              <hr />

              <div className="mt-3">
                <Select name="apartmentId" chooseLabel="Your Apartment" options={apartmentOptions} />
              </div>

              <h4 className="mt-5">
                <strong>Score:</strong>
              </h4>
              <hr />

              <Select name="rentScore" chooseLabel="Apartment Rent" className="mt-2 mb-2" options={scoreOptions} />

              <Select
                name="staffScore"
                chooseLabel="Apartment Staff Interactions"
                className="mt-2 mb-2"
                options={scoreOptions}
              />

              <Select
                name="amenitiesScore"
                chooseLabel="Apartment Amenities"
                className="mt-2 mb-2"
                options={scoreOptions}
              />

              <Select
                name="cleanlinessScore"
                chooseLabel="Apartment Cleanliness"
                className="mt-2 mb-2"
                options={scoreOptions}
              />

              <Select
                name="noiseScore"
                chooseLabel="Apartment Noise Level"
                className="mt-2 mb-2"
                options={scoreOptions}
              />

              <Select name="safetyScore" chooseLabel="Apartment Safety" className="mt-2 mb-2" options={scoreOptions} />

              <Select
                name="maintenanceScore"
                chooseLabel="Apartment Maintenance"
                className="mt-2 mb-2"
                options={scoreOptions}
              />

              <Select
                name="locationScore"
                chooseLabel="Apartment Location"
                className="mt-2 mb-2"
                options={scoreOptions}
              />

              <h4 className="mt-5">
                <strong>Experience:</strong>
              </h4>
              <hr />

              <Select
                name="apartmentExpierence"
                chooseLabel="Overall Apartment Experience"
                className="mt-2 mb-2"
                options={scoreOptions}
              />

              <Select
                name="norhartExpierence"
                chooseLabel="Overall Norhart Experience"
                className="mt-2 mb-2"
                options={scoreOptions}
              />

              <h4 className="mt-5">
                <strong>Recommend:</strong>
              </h4>
              <hr />

              <Select
                name="recommendNorhart"
                chooseLabel="How likely are you to recommend Norhart?"
                className="mt-2 mb-2"
                options={likelyScoreOptions}
              />

              <Select
                name="renewLease"
                chooseLabel="How likely are you to renew your lease with Norhart?"
                className="mt-2 mb-2"
                options={likelyScoreOptions}
              />

              <h4 className="mt-5">
                <strong>Comments:</strong>
              </h4>
              <hr />

              <div className="mb-5">
                <TextArea
                  variant="floating"
                  name="comments"
                  placeholder="Comments"
                  label="How can we improve the Norhart experience?"
                />
              </div>

              <h4 className="mt-3">
                <strong>Contact:</strong>
              </h4>
              <hr />

              <div className="mb-3 mt-3">
                <Input variant="floating" name="name" label="Name" placeholder="John Appleseed" />
              </div>

              <div className="mb-3 mt-3">
                <Input variant="floating" name="phone" type="tel" label="Phone Number" placeholder="(555) 555-1234" />
              </div>

              <div className="mb-3 mt-3">
                <Input
                  variant="floating"
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="john.appleseed@norhart.com"
                />
              </div>

              <div className="mb-3">
                <Checkbox name="canContact" label="Yes, it's ok for Norhart to contact me about my feedback." />
                <div className="mt-3">
                  <i>
                    <strong>Transparency:</strong> We are committed to keeping your information confidential. We do not
                    sell, rent, or lease our data to third parties, and we will not provide your personal information to
                    any third party individual, government agency, or company at any time unless compelled to do so by
                    law.
                    <p />
                    <strong>Opt In:</strong> By providing your phone number, you agree to receive text messages from
                    Norhart regarding updates, offers, and promotions. Reply STOP to opt-out anytime. Message and data
                    rates may apply. For more information, please read our{" "}
                    <a href="https://www.norhart.com/legal/privacy/">https://www.norhart.com/legal/privacy/</a> and{" "}
                    <a href="https://www.norhart.com/legal/terms/">https://www.norhart.com/legal/terms/</a>.
                  </i>
                </div>
              </div>

              <FormErrorMessage />

              <button
                type="submit"
                className="btn btn-md btn-light animate__animated animate__pulse animate__infinite animate__slow mb-5"
                style={{ backgroundColor: "#21a7ff", border: "0px", color: "#ffffff" }}
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                )}
                {isSubmitting ? " Submitting" : "Submit Feedback"}
              </button>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  )
}

interface SuccessProps extends Props {
  apartmentId: string
}

const Success: React.FC<SuccessProps> = (props) => {
  const { apartmentId } = props

  let googleURL
  let facebookURL

  switch (apartmentId) {
    case "ae114b0c-8093-4dd2-8820-c00f21f1f2dc": // "Lexington Lofts"
      googleURL = "https://www.google.com/search?q=norhart"
      facebookURL = "https://www.facebook.com/norhart/"
      break
    case "e3f80467-cf2e-11e8-bb41-02ddd7ef322c": // "Mill Pond Apartments"
      googleURL = "https://www.google.com/search?q=norhart"
      facebookURL = "https://www.facebook.com/norhart/"
      break
    case "e3f80a2b-cf2e-11e8-bb41-02ddd7ef322c": // "Gateway Green Apartments"
      googleURL = "https://www.google.com/search?q=norhart"
      facebookURL = "https://www.facebook.com/norhart/"
      break
    case "ab293683-7d39-432a-a85f-bf68ce0df238": // "Encore Apartments"
      googleURL = "https://www.google.com/search?q=norhart"
      facebookURL = "https://www.facebook.com/norhart/"
      break
    case "e3f8039f-cf2e-11e8-bb41-02ddd7ef322c": // "Legacy Pointe Apartments"
      googleURL = "https://www.google.com/search?q=norhart"
      facebookURL = "https://www.facebook.com/norhart/"
      break
    case "e3f80206-cf2e-11e8-bb41-02ddd7ef322c": // "Springwood Apartments"
      googleURL = "https://www.google.com/search?q=norhart"
      facebookURL = "https://www.facebook.com/norhart/"
      break
    case "e3f8031b-cf2e-11e8-bb41-02ddd7ef322c": // "Heritage Heights Apartments"
      googleURL = "https://www.google.com/search?q=norhart"
      facebookURL = "https://www.facebook.com/norhart/"
      break
    case "e3f8013a-cf2e-11e8-bb41-02ddd7ef322c": // "Greystone Apartments"
      googleURL = "https://www.google.com/search?q=norhart"
      facebookURL = "https://www.facebook.com/norhart/"
      break
    case "e3f7fecc-cf2e-11e8-bb41-02ddd7ef322c": // "Northbrook Apartments"
      googleURL = "https://www.google.com/search?q=norhart"
      facebookURL = "https://www.facebook.com/norhart/"
      break
    case "e3f8029c-cf2e-11e8-bb41-02ddd7ef322c": // "Birchview Apartments"
      googleURL = "https://www.google.com/search?q=norhart"
      facebookURL = "https://www.facebook.com/norhart/"
      break
    case "e3f8058b-cf2e-11e8-bb41-02ddd7ef322c": // "Gateway Green Townhomes"
      googleURL = "https://www.google.com/search?q=norhart"
      facebookURL = "https://www.facebook.com/norhart/"
      break
    default:
      break
  }

  return (
    <section
      className="container-fluid sectionBaseColor pt-5 pb-5"
      style={{
        color: `${props.colorPalette.sectionText}`,
      }}
    >
      <div className="container pl-3 pr-3 text-center">
        <div className="container pb-5"></div>
        <h2
          className="contentHeroTitle"
          style={{
            color: "#365c70",
          }}
        >
          <img src="thanks-for-sharing.png" className="mb-1" />
          <br />
          Thanks For Sharing!
        </h2>

        <h4 className="contentHeroSubTitle mb-5" style={{ color: "#365c70", opacity: "0.8" }}>
          We will share your feedback with all our teams. Thanks again for taking the time to fill out our survey and
          sharing your Norhart experience.
        </h4>

        <h4 className="contentHeroSubTitle mb-5" style={{ color: "#365c70", opacity: "0.8" }}>
          Please, share your Norhart experience with others 🙌
        </h4>

        <div className="d-flex justify-content-center mb-3">
          <a
            className="btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow"
            href={googleURL}
            target="_blank"
            style={{
              borderColor: "#fff",
              color: "#fff",
              border: "0px",
              backgroundColor: "#21a7ff",
            }}
          >
            Google
          </a>

          <a
            className="ms-3 btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow"
            href={facebookURL}
            target="_blank"
            style={{
              borderColor: "#fff",
              color: "#fff",
              border: "0px",
              backgroundColor: "#21a7ff",
            }}
          >
            Facebook
          </a>
        </div>
      </div>
    </section>
  )
}
