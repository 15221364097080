import React, { InputHTMLAttributes } from "react"
import { FieldValidator, useField } from "formik"
import { Variant } from "./helpers"
import classNames from "classnames"

interface SelectProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  name: string
  chooseLabel?: string
  options: string[][]
  variant?: Variant
  validate?: FieldValidator
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ label, name, chooseLabel, options, variant, validate, className, ...props }, ref) => {
    const [field, meta] = useField({ name, validate })

    const isFloating = variant == "floating"

    const labelComp = <label htmlFor={field.name}>{label}</label>
    const selectComp = (
      <select
        ref={ref}
        className={classNames("form-select", className, {
          "is-invalid": meta.touched && meta.error,
        })}
        {...field}
      >
        <option value={""}>{chooseLabel ?? "Choose...."}</option>
        {options.map((option, index) => {
          return (
            <option value={option[0]} key={option[0]}>
              {option[1]}
            </option>
          )
        })}
      </select>
    )

    if (label == null) {
      return (
        <div>
          {selectComp}
          {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
        </div>
      )
    }

    return (
      <div className={classNames({ "form-floating": isFloating })} {...props}>
        {isFloating ? (
          <>
            {selectComp}
            {labelComp}
          </>
        ) : (
          <>
            {labelComp}
            {selectComp}
          </>
        )}
        {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
      </div>
    )
  }
)

/** export */
export default Select
